const homeCont = [{
        id: 1,
        title: 'Pourquoi devriez-vous travailler avec nous?',
        body: 'Notre société de TI possède une grande expérience dans la gestion de projets de transformation numérique. Nous offrons des solutions clés en main pour vous aider à économiser du temps et des coûts. Nous sommes également flexibles et capables de nous adapter rapidement aux besoins changeants de votre entreprise.',
    },
    {
        id: 2,
        title: 'Pourquoi nous sommes spéciaux ?',
        body: ' nous avons une expertise technique solide dans de nombreux domaines, nous sommes engagés à offrir des services de qualité supérieure à nos clients, nous sommes flexibles et capables de nous adapter rapidement aux besoins changeants de nos clients, et nous maintenons une culture d\'innovation continue en adoptant les dernières technologies et en encourageant la créativité de notre équipe.',
    },

]
export default homeCont