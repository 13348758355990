import styled from 'styled-components'

export const Applybtn = styled.div `
    
    display:flex;
    background-color:#f66b0c;
    color:white;
    cursor:pointer;
    padding:7px 20px;
    border-radius:5px;
    transition: all .4s ease-in-out;
    width:106px;
    
    &:hover{
        background-color:black;
        padding-right:50px;
        width:140px;
        };
    & img{
        visibility:hidden;
    }
    &:hover img{
        visibility: visible;
        transform: translateX(60%);
        transition: all .5s ease-in-out;

        };
`