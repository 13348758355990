import React from "react";
import FormExample from "../components/formExample";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { ImLocation2 } from "react-icons/im";
import {
  AidePart,
  ContactContent,
  ContactPart,
  Container,
} from "../components/styles/Contact.styled";
import { PostCard } from "../components/styles/JobDetails.styled";

function Contact() {
  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Container style={{ overflow: "hidden" }} onContextMenu={handleContextMenu}>
      <div className="row">
        <ContactPart>
          <ContactContent>
            <h5>Emails</h5>
            <hr
              style={{ height: "3px", width: "80px", backgroundColor: "#fff" }}
            />
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
              <IoMdMail /> info@somadsi.ma
            </p>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
              <IoMdMail /> assistant@somadsi.ma
            </p>

            <div style={{ marginBottom: "50px", marginTop: "50px" }}>
              <h5>numéro de téléphone</h5>
              <hr
                style={{
                  height: "3px",
                  width: "80px",
                  backgroundColor: "#fff",
                }}
              />
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                <FaPhoneSquareAlt /> +212 678 053 461
              </p>
            </div>

            <div
              style={{ marginBottom: "50px", marginTop: "50px", width: "75%" }}
            >
              <h5>Adresse</h5>
              <hr
                style={{
                  height: "3px",
                  width: "80px",
                  backgroundColor: "#fff",
                }}
              />
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                <ImLocation2 /> Groupe Attakkaddoum Gh2-17 2éme Etg - Sidi
                Bernoussi Casablanca, Maroc
              </p>
            </div>
          </ContactContent>
        </ContactPart>
        <Container className="col-sm-6">
          <AidePart>
            <PostCard>
              <div style={{ padding: "0 0 0px 40px", margin: "0 0 -30px 0" }}>
                <h2>Comment pouvons nous aider?</h2>
                <hr
                  style={{
                    height: "3px",
                    width: "100px",
                    backgroundColor: "#f66b0c",
                  }}
                />
              </div>
              <FormExample offre={"contact"} direction={"applicants"} />
            </PostCard>
          </AidePart>
        </Container>
      </div>
    </Container>
  );
}

export default Contact;
