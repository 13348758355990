import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import AutoTyping, { BlinkCursor } from "react-auto-typing";
import { ConSlider, HeroVideo, Slider, Video } from "./styles/home.styled";

export default function slider() {
  return (
    <ConSlider
      className="carousel slide"
      data-ride="carousel"
      data-interval="4000"
    >
      <div className="carousel-inner">
        <div
          className="carousel-item active"
          style={{
            position: "initial",
          }}
        >
          <HeroVideo>
            <Video className="videoTag" autoPlay loop muted>
              <source src="images/Plexus.webm" type="video/mp4" />
            </Video>
            <span/>
          </HeroVideo>
          <Slider
            className="carousel-box"
            style={{
              margin: "0px",
            }}
          >
            <h3>
              Pour ceux qui{" "}
              <AutoTyping
                active // <boolean>
                textRef=" veulent agrandir leur entreprise" // <string>
                writespeed={150} // <number>
                deletespeed={150} // <number>
                delaytowrite={1000} // <number>
                delaytodelete={2000} // <number>
              />
              <BlinkCursor
                active // <boolean>
                blinkspeed={500} // <number>
              />
            </h3>
            <h5>Faites grandir votre entreprise avec nous</h5>
            <Nav id="nav" style={{ marginTop: "70px", marginLeft: "-10px" }}>
              <Link id="link" className="nav-link" to="/services">
                Services
              </Link>
              <Link id="link" className="nav-link" to="/solutions">
                Solutions
              </Link>
              <Link id="link" className="nav-link" to="/offers">
                Trouver un emploi
              </Link>
              <Link id="link" className="nav-link" to="/contact">
                Contact
              </Link>
            </Nav>
          </Slider>
        </div>
      </div>
    </ConSlider>
  );
}
