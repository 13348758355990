import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalFooter,
  BtnExit,
  BtnAttach,
  Input,
  EreaText,
  ModalImg,
  ModalHero,
  ModalContactContainer,
  InputContainer,
  Form,
  Label,
  BtnSubmit,
} from "../components/styles/Modal.styled";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { AttachFile } from "../components/SvgContainer";
import { Toast, notifyWarn, notify, notifyErr } from "../components/Toast";
import axios from "axios";
import emailjs from "emailjs-com";

const ModalContact = (props) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("Attach File");
  const [input, setInput] = useState({
    nom_prenom: "",
    email: "",
    message: "",
  });

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const handleClick = async (event) => {
    event.preventDefault();

    const newContact = {
      nom_prenom: input.nom_prenom,
      email: input.email,
      message: input.message,
    };

    try {
      if (!newContact.nom_prenom || !newContact.email || !newContact.message) {
        notifyWarn(
          "Veuillez remplir tous les champs nécessaires.",
          "bottom-center"
        );
        return;
      }

      // Use Axios to upload the file to the PHP endpoint
      let uploadResponse;
      let fileLink = null;
      if (file) {
        uploadResponse = await uploadFileToServer(file);
        fileLink = uploadResponse.fileLink;
      }

      const emailServiceId = "service_ntu0pbq";
      const templateId = "template_45k679v";
      const userId = "NphJ9IyJ5k6e2IqWq";

      const emailParams = {
        to_email: "info@somadsi.ma",
        subject: "Nouvelle demande from contact",
        nom_prenom: input.nom_prenom,
        email: input.email,
        message: input.message,
        offre: props.titre,
        file: fileLink,
      };

      await emailjs.send(emailServiceId, templateId, emailParams, userId);

      const formData = new FormData();
      formData.append("nom_prenom", newContact.nom_prenom);
      formData.append("email", newContact.email);
      formData.append("message", newContact.message);

      const createContactUrl =
        "https://www.somadsi.ma/custom-offer-plugin/create-contact.php";

      await fetch(createContactUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newContact),
      });

      notify("bottom-center");
    } catch (error) {
      notifyErr(
        "Votre demande n'a pas été envoyée avec succès. Veuillez réessayer plus tard.",
        "bottom-center"
      );
    }
  };

  const uploadFileToServer = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `https://www.somadsi.ma/custom-offer-plugin/upload.php?direction=contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("File upload to server failed", error);
      throw error;
    }
  };

  const control = useAnimation();
  const [ref, inView] = useInView();
  const boxVariant = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 },
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }

    if (props.modalIsOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [control, inView, props.modalIsOpen]);

  if (!props.modalIsOpen) {
    return null;
  }

  return (
    <Modal isOpen={props.isOpen} style={{ zIndex: "99999" }}>
      <motion.div
        ref={ref}
        className="title"
        variants={boxVariant}
        initial="hidden"
        animate={control}
        transition={{ type: "spring" }}
      >
        <ModalContent>
          <ModalHero>
            <ModalContactContainer>
              <Form onSubmit={handleClick}>
                <ModalHeader>
                  <ModalTitle>Voulez-vous travailler avec nous?</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <p>
                    Parlez-nous simplement de votre projet, quels sont vos
                    objectifs, et commençons.
                  </p>
                  <InputContainer>
                    <Input
                      type="text"
                      name="nom_prenom"
                      placeholder="Votre nom"
                      onChange={(e) =>
                        setInput({ ...input, nom_prenom: e.target.value })
                      }
                    />
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={(e) =>
                        setInput({ ...input, email: e.target.value })
                      }
                    />
                  </InputContainer>
                  <EreaText
                    placeholder="Message"
                    name="message"
                    style={{ maxHeight: "100px", minHeight: "100px" }}
                    onChange={(e) =>
                      setInput({ ...input, message: e.target.value })
                    }
                  />
                </ModalBody>
                <ModalFooter>
                  <Fragment>
                    <Label htmlFor="customFile" className="labels">
                      <AttachFile />
                      <BtnAttach
                        type="file"
                        id="customFile"
                        name="file"
                        onChange={onChange}
                      />
                      {filename}
                    </Label>
                    <BtnSubmit type="submit">Envoyer le message</BtnSubmit>
                    <Toast />
                  </Fragment>
                </ModalFooter>
              </Form>
            </ModalContactContainer>
          </ModalHero>
          <ModalHero>
            <ModalImg src="../images/mailer.gif" width="100px" />
          </ModalHero>
          <a onClick={props.onClose} href="#bottom">
            <BtnExit>
              <img src="../images/exit.webp" alt="X" />
            </BtnExit>
          </a>
        </ModalContent>
      </motion.div>
    </Modal>
  );
};

export default ModalContact;
