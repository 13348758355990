import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  html::-webkit-scrollbar {
    width: 20px;
  }

  html::-webkit-scrollbar-track {
    background-color: black;
  }

  html::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
  }

  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    margin-top: -9vh;
  }
`;

export const Background = styled.div`
  img {
    width: 100px;
  }
`;

export const Imgs = styled.img`
  width: 80%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 8%;
  height: 111vh;
  left: 0;
  width: 100%;
  background: ${({ bg }) => bg || 'url("../../images/back.png")'};
  //background-size: auto 120%;
  opacity: 0, 3;
  overflow: auto;
  overflow-y: hidden;
  margin-top: -10vh;

  img {
    width: 80%;
  }

  & > div {
    flex: 1;
  }
  h1 {
    font-size: 200%;
    font-weight: bold;
  }
  p {
    font-size: 100%;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 100vh;
    padding-top: 15vh;
    margin: 0;
    display: block;
    text-align: -webkit-center;
    background-image: url("../../images/back.png");

    h1 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
      font-weight: 500;
    }
    img {
      margin: 10%;
      width: 40%;
    }
  }
`;

export const Clt = styled.div`
  padding: 8% 10%;
  background: ${({ bg }) => bg || 'url("../../images/back.png")'};
  //background-size: cover;

  h1 {
    font-size: 200%;
    font-weight: bold;
    margin-bottom: 30px;
  }

  p {
    color: grey;
    line-height: 1;
  }

  img {
    width: 80px;
    margin: 30px 30px 0 0;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    align-content: center;
    img {
      width: 70px;
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const Pop = styled.div`
  padding: 10%;
  background: ${({ bg }) => bg || 'url("../../images/back.png")'};
  //background-size: cover;

  h1 {
    font-size: 350%;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 17px;
    }
  }
`;

export const Btn = styled.div`
  display: contents;
  color: #f66b0c;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: black;
    transition: 0.5s ease-out;
  }
`;

export const BtnMore = styled.button`
  color: white;
  background: #f66b0c;
  border: 0px;
  padding: 6px 15px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  margin-right: 21px;
  margin-top: 7%;
  &:hover {
    background-color: black;
    transition: 0.2s ease-out;
    border-radius: 1px 10px;
    box-shadow: grey 2px 2px 2px;
  }
`;

export const Para = styled.p`
  color: #413f42;
  font-weight: 400;
  margin-top: -15px;
`;

export const H1 = styled.h1`
  margin-bottom: 10%;
`;

export const WordCard = styled.div`
  display: flex;
  align-items: center;
  padding: 2%;
  height: 100vh;
  left: 0;
  width: 100%;
  background: ${({ bg }) => bg || 'url("../../images/back.png")'};
  background-size: auto 100%;
  opacity: 0, 3;
  overflow-y: hidden;

  & > div {
    flex: 1;
  }

  p {
    width: 70%;
    margin: auto;
    font-weight: 600;
    fontsize: 18px;
    text-align: center;
  }
  img {
    border-radius: 50%;
    -webkit-box-shadow: inset -1px 3px 8px 5px #1f87ff, 2px 5px 16px 0px #0b325e,
      2px 1px 26px -1px rgba(0, 0, 0, 0);
    box-shadow: inset -1px 3px 8px 5px #1f87ff, 2px 5px 16px 0px #0b325e,
      2px 1px 26px -1px rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 768px) {
    display: flex;
    text-align: -webkit-center;
    p {
      width: 85%;
      margin: auto;
      font-size: 15px;
      font-weight: 500;
    }
    h5 {
      font-size: large;
    }
  }
`;

export const PersonCard = styled.div`
  padding: 5px;
  background: url("images/backNotFound.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    2px 1px 26px -1px rgba(0, 0, 0, 0);
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    2px 1px 26px -1px rgba(0, 0, 0, 0);

  @media screen and (max-width: 768px) {
    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 400) {
    p {
      text-align: start;
    }
  }
`;

export const CltCard = styled.div`
  text-align: center;
  margin-top: 50px;
  h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  p {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
  }
  img {
    width: 100px;
    margin: 50px 80px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 40px;
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 17px;
    }

    img {
      width: 60px;
      margin: 10px 20px;
    }
  }
`;

export const ImgClt = styled.img`
  &:hover {
    transform: scale(1.4);
  }
`;
