import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "@material-ui/core";

const variantsLeft = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: -10, y: -30 },
};

const variantsRight = {
  hidden: { opacity: 0, x: 400, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: -10, y: -100 },
};

const AnimateObj = ({ textH1, textP, textP2, Img, Width }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("enter");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const isMobile = useMediaQuery("(max-width: 767px)");

  if (isMobile) {
    return (
      <div className="title">
        {textH1 !== "" ? <h1>{textH1}</h1> : <p>{textP}</p>}
        {textP2 !== "" || textH1 !== "" || textP !== "" ? (
          <p>{textP2}</p>
        ) : (
          <img src={Img} alt="img" width={Width} />
        )}
      </div>
    );
  }

  return (
    <motion.div
      ref={ref}
      className="title"
      variants={textH1 !== "" || textP2 !== "" ? variantsLeft : variantsRight}
      initial="hidden"
      animate={control}
      transition={{ type: "spring" }}
    >
      {textH1 !== "" ? <h1 id="headerLine">{textH1}</h1> : <p>{textP}</p>}
      {textP2 !== "" || textH1 !== "" || textP !== "" ? (
        <p id="Pcontent">{textP2}</p>
      ) : (
        <img src={Img} alt="img" width={Width} />
      )}
    </motion.div>
  );
};

export default AnimateObj;
