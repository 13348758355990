import React from "react";
import { Nav } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import Word from "../components/word";
import AnimateObj from "../components/Framer";
import { Questions, Rawdaw } from "../components/styles/home.styled";
import homeCont from "../components/homeinfo";
import Happyclient from "../components/happyclient";
import { Container, Flex } from "../components/styles/Services.styled";

const About = () => {
  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Container onContextMenu={handleContextMenu}>
      <Flex style={{ overflow: "hidden" }}>
        <div>
          <div>
            <AnimateObj
              textH1={"À propos de nous"}
              textP={""}
              textP2={""}
              Img={""}
            />
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
                margin: "30px 0 30px 0",
              }}
            />
            <AnimateObj
              textH1={""}
              textP={""}
              textP2={
                "Société marocaine de développement & système d'information. SOMADSI se classe parmi les fournisseurs de services en IT, l'équipes de SOMADSI maîtrisent la complexité des projets applicatifs et mettent en œuvre les solutions les mieux adaptées aux besoins des clients."
              }
              Img={""}
            />
          </div>
          <Nav id="nav" style={{ marginTop: "70px", marginLeft: "-10px" }}>
            <a
              href="https://www.facebook.com/SOMADSI-101215478486277"
              target="_blank"
              rel="noreferrer"
              id="linkicon"
              className="nav-link"
            >
              <FaFacebookSquare style={{ fontSize: "1.5em" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/somadsi/"
              target="_blank"
              rel="noreferrer"
              id="linkicon"
              className="nav-link"
              to="/about"
            >
              <FaLinkedin style={{ fontSize: "1.5em" }} />
            </a>
          </Nav>
        </div>
        <div style={{ textAlign: "center" }}>
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={""}
            Img={"images/giphy.gif"}
            Width={"450px"}
          />
        </div>
      </Flex>
      <Flex bg="#F3F3F3" style={{ overflow: "hidden" }}>
        <div>
          <AnimateObj
            textH1={"Nos approches"}
            textP={""}
            textP2={""}
            Img={""}
          />
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "30px 0 30px 0",
            }}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "La société SOMADSI adopte une approche centrée sur le client, en proposant des solutions personnalisées qui répondent aux besoins spécifiques de chaque client. Elle met également en place une approche axée sur la qualité en adoptant des normes strictes pour garantir la fiabilité et la performance de ses solutions. Enfin, SOMADSI travaille en étroite collaboration avec ses clients pour comprendre leurs besoins et leurs objectifs, et construire une relation de confiance basée sur l'écoute, le conseil et le soutien continu, adoptant ainsi une approche collaborative."
            }
            Img={""}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={""}
            Img={"images/Square.gif"}
            style={{ width: "350px" }}
          />
        </div>
      </Flex>
      <Happyclient />
      <Word />
      <Flex className="firstinfo">
        <Questions>
          {homeCont.map((item, index) => (
            <Rawdaw
              className="row"
              layout={item.id % 2 === 0 && "row-reverse"}
              key={index}
            >
              <div className=" col title">
                <AnimateObj
                  textH1={item.title}
                  textP={""}
                  textP2={""}
                  Img={""}
                />
              </div>
              <hr
                style={{
                  height: "3px",
                  width: "100px",
                  color: "#d1d1d1",
                  marginTop: "50px",
                  transform: "rotate(90deg)",
                }}
              />
              <div className="col">
                <AnimateObj
                  textH1={""}
                  textP={item.body}
                  textP2={""}
                  Img={""}
                />
              </div>
            </Rawdaw>
          ))}
        </Questions>
      </Flex>
    </Container>
  );
};

export default About;
