import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Applybtn } from "./styles/JobStyled";
import { BsTagFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { CardOffers } from "./styles/Offers.styled";

function Job({ item: { id, titre, tag, heure_travail, ville } }) {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      ref={ref}
      className="title"
      variants={boxVariant}
      initial="hidden"
      animate={control}
      transition={{ type: "spring" }}
      style={{ margin: "auto" }}
    >
      <CardOffers className="card" id="jobCard">
        <Link
          to={"/offers/" + id}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="card-body row">
            <div className="col-sm-9">
              <h5 className="card-title">{titre}</h5>
              <p className="card-text" style={{ color: "#f66b0c" }}>
                <BsTagFill /> {tag}
              </p>
            </div>

            <div className="col-sm-3 text-left">
              <p>
                {heure_travail} | {ville}
              </p>
              <Applybtn>
                Appliquer
                <img
                  src="images/last-but2.svg"
                  alt=""
                  style={{ width: "20px", marginLeft: "7px" }}
                />
              </Applybtn>
            </div>
          </div>
        </Link>
      </CardOffers>
    </motion.div>
  );
}

export default Job;
