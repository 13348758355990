import styled from "styled-components";

export const Container = styled.div`
    @media screen and (max-width:768px){
        display: flex;
        margin: 0;
        margin-left: 0vw;
        width: 100%;
        max-width: 100%;
        padding-top: 10%; 
    }
`

export const ContactContent = styled.div`
    margin-left:80px;
    margin-top:80px;  

    @media screen and (max-width:768px){
        margin: 5vw;
        width: 100%;
    }
`

export const ContactPart = styled.div`
    background:url("../../images/contactBack.png");
    background-size: 27px; 
    opacity: 95%;
    color: black;
    border-radius: 5px;
    width: 50%;
    
    @media screen and (max-width:768px){
        width: 100%;
    }
    
`


export const AidePart = styled.div`
    
    padding-top: 10%;

    @media screen and (max-width:768px){
        margin: -3vw;
        margin-top: 0vw;
        width: 80.5vw;
    }
`