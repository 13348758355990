import styled from 'styled-components';

export const Box = styled.div `
  padding: 80px 0px;
  background: #F3F3F3;
  bottom: 0;
  width: 100%;


  @media (max-width: 1000px) {
    padding: 70px 30px;
  }

  @media screen and (max-width:500px){
    font-size: initial;
    font-weight: 500;
    padding: 50px 0;
  }
`

export const Container = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 66px;

  @media screen and (max-width:500px){
    margin: 0;
    
  }
	
`

export const Column = styled.div `
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 40px;
  width:200px;
  @media screen and (max-width:500px){
    margin: 10%;
    width:80%;
    margin-bottom:-50px;
  }
`;

export const Row = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fill, 
                         minmax(185px, 1fr));
  grid-gap: 100px;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a `
color: black;
margin-bottom: 10px;
font-size: 16px;
text-decoration: none;

&:hover {
	color: #f66b0c;
	transition: 200ms ease-in;
}
`

export const Heading = styled.p `
  font-size: 20px;
  color: dark;
  margin-bottom: 30px;
  font-weight:600;

  @media screen and (max-width:500px){
     margin-bottom: 10px;
  }
`