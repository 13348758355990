import styled from "styled-components";

export const MainSection = styled.div`
  padding: 7% 0 0 10%;
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 17px;
    }
  }
`;

export const StyledCard = styled.div`
  position: relative;

  width: 24rem;
  height: 26rem;
  min-height: 24rem;
  margin: 50px 30px 40px 0px;
  padding: 25px;

  background: white;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 95%;
    height: 27rem;
    margin: 40px 20px 20px 0px;

    h1 {
      font-size: 90%;
      font-weight: 600;
      margin: 10px 0;
    }
  }

  img {
    width: 50px;
  }

  & > div {
    flex: 1;
  }

  h1 {
    font-size: 120%;
    font-weight: 600;
    margin: 10px 0;
  }
  p {
    font-size: 83%;
    line-height: 200%;
    color: grey;
    margin: 20px 0;
    white-space: pre-wrap;
  }
  a {
    outline: none;
    border: none;
    color: #000;
    text-decoration: none;

    position: absolute;
    bottom: 8%;
  }
  &:hover {
    transform: translateY(-15px);
    transition: 0.5s;
    box-shadow: 5px 5px 10px #dfdfde;
    a {
      color: #ff561d;
      outline: none;
      border: none;
    }
    .bi-arrow-right {
      fill: #ff561d;
      transform: translateX(50%);
      transition: 0.3s;
    }
  }
`;

export const CardContainer = styled.div`
  display: inline-table;

  @media screen and (max-width: 768px) {
    width: 95%;
    height: 27rem;
    margin: 40px 20px 20px 0px;

    h1 {
      font-size: 90%;
      font-weight: 600;
      margin: 10px 0;
    }
  }
`;

export const DetailsServ = styled.div`
  p {
    white-space: pre-wrap;
    font-weight: 500;
  }
`;
