import React from "react";
import InlineCard from "../components/InlineCard";
import AnimateObj from "../components/Framer";
import {
  Container,
  Flex,
  FlexInline,
} from "../components/styles/Solutions.styled";
import contentServ from "../components/contentServices";
import Client from "../components/client";
import Stack from "../components/stack";

const Solutions = () => {
  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Container style={{ fontWeight: "500" }} onContextMenu={handleContextMenu}>
      <Flex>
        <div>
          <AnimateObj
            textH1={"8 ans de spécialité"}
            textP={""}
            textP2={""}
            Img={""}
          />
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "30px 0 30px 0",
            }}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "Spécialiste des logiciels de gestion depuis plus de 8 ans."
            }
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "Notre vocation est de vous apporter des solutions clés en main, simples et intuitives pour aider vos collaborateurs à être plus performants et plus performants dans leurs tâches quotidiennes."
            }
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              " Avec une équipe de consultants qui se sont distingués sur le marché marocain par leur compétence et leur savoir-faire, nous les aidons à mettre en place des solutions logicielles pour optimiser leur gestion au quotidien."
            }
            Img={""}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <AnimateObj textH1={""} imgUrl={"../../images/solutions.gif"} />
        </div>
      </Flex>
      <Flex bg="#F3F3F3">
        <div className="col">
          <AnimateObj
            textH1={"Compétences et savoir-faire"}
            textP={""}
            textP2={""}
            Img={""}
          />
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "30px 0 30px 0",
            }}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "Une équipe de consultants spécialisés dans les logiciels de gestion avec une forte connaissance de la gestion d'entreprise."
            }
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={"Savoir-faire et approche professionnelle."}
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "Une forte implication de nos équipes dans l'accomplissement de leurs missions."
            }
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={"Expérience et réalisations prestigieuses."}
            Img={""}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <AnimateObj textH1={""} imgUrl={"../../images/skills.gif"} />
        </div>
      </Flex>
      <FlexInline>
        {contentServ.map((item, index) => (
          <InlineCard key={index} item={item} />
        ))}
      </FlexInline>
      <Stack />
      <Client />
    </Container>
  );
};

export default Solutions;
