import React, { useState, useEffect } from "react";
import { BsTagFill } from "react-icons/bs";
import FormExample from "./formExample";
import { FaGraduationCap } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { MdWork } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { Container, PostCard } from "./styles/JobDetails.styled";
import LoadingSpinner from "./LoadingSpinner";

function JobDetails({
  item: {
    id,
    titre,
    tag,
    description,
    niveau,
    experience,
    heure_travail,
    langue,
    necessite,
  },
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Change the delay time as needed

    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container className="row">
          <div className="col-sm-6" style={{ marginBottom: "50px" }}>
            <div className="card">
              <div className="card-header" style={{ padding: "30px" }}>
                <h1>{titre}</h1>
                <p className="card-text" style={{ color: "#f66b0c" }}>
                  <BsTagFill /> {tag}
                </p>
              </div>
              <div className="card-body" style={{ padding: "30px" }}>
                <div style={{ width: "100%" }}>
                  <h2>Description de l'emploi</h2>
                  <hr
                    style={{
                      height: "5px",
                      width: "100px",
                      backgroundColor: "#f66b0c",
                    }}
                  />
                  <p style={{ padding: "15px 0" }}>{description}</p>

                  <div style={{ marginBottom: "40px" }}>
                    <div className="row" style={{ margin: "0", padding: "0" }}>
                      <div className="col-sm-4" style={{ padding: "10px" }}>
                        <FaGraduationCap
                          style={{ fontSize: "30px", margin: "10px" }}
                        />
                        <strong>{niveau}</strong>
                      </div>
                      <div className="col-sm-8" style={{ padding: "10px" }}>
                        <BiTimeFive
                          style={{ fontSize: "30px", margin: "10px" }}
                        />
                        <strong>{experience}</strong>
                      </div>
                    </div>
                    <div className="row" style={{ margin: "0", padding: "0" }}>
                      <div className="col-sm-4" style={{ padding: "10px" }}>
                        <MdWork style={{ fontSize: "30px", margin: "10px" }} />
                        <strong> {heure_travail} </strong>
                      </div>
                      <div className="col-sm-8" style={{ padding: "10px" }}>
                        <BiWorld style={{ fontSize: "30px", margin: "10px" }} />
                        <strong> {langue} </strong>
                      </div>
                    </div>
                  </div>

                  <h2>Exigences de l'emploi</h2>
                  <hr
                    style={{
                      height: "3px",
                      width: "100px",
                      backgroundColor: "#f66b0c",
                    }}
                  />
                  <ul style={{ padding: "30px" }}>
                    <div dangerouslySetInnerHTML={{ __html: necessite }} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <PostCard className="col-sm-6">
            <div style={{ padding: "0 0 40px 40px", margin: "0 0 -30px 0" }}>
              <h2>Voulez-vous postuler pour ce poste ?</h2>
              <hr
                style={{
                  height: "3px",
                  width: "100px",
                  backgroundColor: "#f66b0c",
                }}
              />
            </div>
            <FormExample offre={titre} direction={"applicants"} />
          </PostCard>
        </Container>
      )}
    </div>
  );
}

export default JobDetails;
