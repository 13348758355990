import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import { Clt, CltCard, ImgClt } from './styles/Services.styled';

function Happyclient() {

    const cltBrand = [
        {
          id: 'Axa',
          title: 'AXA',
          image: 'axa.webp',
        },
        {id: 'bea',
        title: 'Bea Concept',
        image: 'bea.webp',
        },
        {
          id: 'ofppt',
          title: 'OFPPT',
          image: 'ofppt.webp',
        },
        {
          id: 'ibc',
          title: 'International Business Consulting',
          image: 'ibc.webp',
        }
      ];

    const control = useAnimation();
    const [ref, inView] = useInView();
    const boxVariant = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0.8 }
    };

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <Clt>
        <motion.div
            ref={ref}
            className="title"
            variants={boxVariant}
            initial="hidden"
            animate={control}
            transition={{ type: 'spring' }}>
                <CltCard>
                    <h1>Clients satisfaits</h1>
                    <hr style={{ height: '3px', width: '100px', backgroundColor: '#f66b0c', margin: 'auto' }} />
                    <p>Nous sommes référencés par certaines des entreprises les plus performantes !</p>
                    <div style={{ marginTop: '30px' }}>
                        {cltBrand.map((item, index) => (
                            <div key={index} style={{ display: 'inline-block' }}>
                                <ImgClt src={`../images/clients/${item.image}`} alt=''/>
                            </div>
                        ))}
                    </div>
                </CltCard>
            </motion.div>
        </Clt>
    )
}

export default Happyclient