import styled from "styled-components";

export const ContainerNo = styled.div`
  position: relative;
  width: 100%;
`;

export const FlexNo = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 8%;
  height: 100vh;
  left: 0;
  width: 100%;
  background: ${({ bg }) => bg || 'url("../../images/BackNotFound.png")'};
  overflow: auto;
  overflow-y: hidden;
  margin-top: -10vh;

  @media screen and (max-width: 768px) {
    padding-top: 20vh;
    display: block;
    text-align: -webkit-center;
  }
`;

export const Left = styled.div`
  flex: 2;
  text-align: center;
  align-items: center;
  text-transform: math-auto;
  letter-spacing: 2px;
  line-height: 40px;

  @font-face {
    font-family: "Rocher";
    src: url(https://assets.codepen.io/9632/RocherColorGX.woff2);
  }

  title {
    font-family: Rocher;
    base-palette: 6;
  }

  p {
    transition: font-weight 250ms ease-in-out;
    p:hover {
      font-weight: 900;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
    line-height: 25px;

    title {
      font-family: Rocher;
      font-size: 1px;
    }
  }
`;

export const Right = styled.div`
  flex: 3;
  text-align: center;
`;

export const Img = styled.img`
  width: 70%;
  height: 70%;
  animation: animate 2s infinite ease alternate;

  @keyframes animate {
    to {
      transform: translateY(30px);
    }
  }
`;

export const BtnNo = styled.button`
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
  }
`;

export const AstNo = styled.div`
  position: relative;
`;
