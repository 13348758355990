import React, { Component } from "react";
import { Container } from "../components/styles/Services.styled";
import bodyCards from "../components/BodyCard";
import ItemServices from "../components/ItemServices";
import Client from "../components/client";
import ContactPop from "../components/contactpop";

class ServicesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idService: this.props.idServ,
    };
  }

  //desactivate right-click on objects
  handleContextMenu = (e) => {
    e.preventDefault();
  };

  render() {
    if (this.state.idService !== undefined) {
      return (
        <Container onContextMenu={this.handleContextMenu}>
          {bodyCards.map((item, i) => {
            if (item.id === this.state.idService) {
              return <ItemServices key={i} item={item} />;
            } else {
              return null;
            }
          })}
          <hr style={{ height: "3px" }} />
          <Client />
          <hr style={{ height: "3px" }} />
          <ContactPop />
        </Container>
      );
    } else {
      return (
        <h3
          styles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          NOT FOUND
        </h3>
      );
    }
  }
}

export default ServicesDetails;
