import React, { useEffect } from "react";
import { Flex, Container, BtnMore } from "../components/styles/Services.styled";
import contentServ from "../components/contentServices";
import Card from "../components/Card";
import { MainSection } from "../components/styles/Sections.styled";
import AnimateObj from "../components/Framer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Client from "../components/client";
import Stack from "../components/stack";
import ContactPop from "../components/contactpop";
import { Link } from "react-router-dom";

const Services = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Container onContextMenu={handleContextMenu}>
      <Flex bg="#F3F3F3">
        <div>
          <AnimateObj textH1={"Nos services"} textP={""} textP2={""} Img={""} />
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "30px 0 30px 0",
            }}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={"Les meilleurs logiciels pour gérer votre entreprise"}
            Img={""}
          />
        </div>
        <div>
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={""}
            Img={"images/services.gif"}
            Width={"450px"}
          />
        </div>
      </Flex>
      <Flex style={{ overflow: "hidden" }}>
        <div>
          <AnimateObj
            textH1={"Nos solutions"}
            textP={""}
            textP2={""}
            Img={""}
          />
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "30px 0 30px 0",
            }}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={"Des solutions indisponsable pour la gestion des SME/SMI."}
            Img={""}
          />
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={
              "SOMADSI vous accompagne dans la mise en place ces solutions de gestion taillées pour vous."
            }
            Img={""}
          />
          <Link to="/solutions">
            <BtnMore>voir plus d'options</BtnMore>
          </Link>
        </div>
        <div style={{ textAlign: "center" }}>
          <AnimateObj
            textH1={""}
            textP={""}
            textP2={""}
            Img={"images/solution.gif"}
            Width={"450px"}
          />
        </div>
      </Flex>
      <MainSection style={{ background: "#F3F3F3" }}>
        <motion.div
          ref={ref}
          className="title"
          variants={boxVariant}
          initial="hidden"
          animate={control}
          transition={{ type: "spring" }}
        >
          <h1>Développement de logiciels et de produits</h1>
          {contentServ.map((item, index) => (
            <Card key={index} item={item} />
          ))}
        </motion.div>
      </MainSection>
      <Client />
      <Stack />
      <ContactPop />
    </Container>
  );
};

export default Services;
