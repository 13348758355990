import React, { Component } from "react";
import JobDetails from "../components/JobDetails";

export default class JobPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      idPost: this.props.idPost,
    };
  }

  async getPosts(keyword) {
    try {
      this.setState({ loading: true });

      let url;

      if (!keyword) {
        // URL for fetching post by id
        url = `https://www.somadsi.ma/custom-offer-plugin/fetch-data.php?param=offers&id=${this.state.idPost}`;
      }

      const response = await fetch(url);

      // Check if the response status is OK (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        loading: false,
        Posts: data,
      });
    } catch (error) {
      console.error("Error fetching posts:", error.message);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getPosts();
  }

  render() {
    const { Posts, idPost } = this.state;

    if (idPost !== undefined) {
      return (
        <div>
          {Posts.map((item) => {
            if (item.id === idPost) {
              return <JobDetails key={item.id} item={item} />;
            } else {
              return null;
            }
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
