import styled from 'styled-components'

export const BtnMore = styled.button `
    color:white;
    background: #f66b0c;
    border: 0px;
    padding: 6px 15px;
    font-size: 15px;
    border-radius: 3px;
    margin-right: 21px;
    margin-top: 7%; 
    &:hover {
        background-color: black;
        transition: 0.2s ease-out;
        border-radius: 1px 10px;
        box-shadow: grey 2px 2px 2px; 
    }   
`