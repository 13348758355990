import React from "react";
import { Flex } from "../components/styles/Services.styled";
import "bootstrap/dist/css/bootstrap.min.css";
import { DetailsServ } from "./styles/Sections.styled";
import AnimateObj from "./Framer";
import { BtnNo } from "./styles/OffersNo.styled";
import { Link } from "react-router-dom";

function bodySplit(body) {
  var brk = body.split("§");
  var res = brk.join("\n");
  return res;
}

export default function ItemServices({ item: { id, title, body, image } }) {
  return (
    <Flex style={{ overflow: "hidden" }}>
      <DetailsServ>
        <AnimateObj textH1={title} textP={""} textP2={""} Img={""} />
        <hr
          style={{
            height: "3px",
            width: "100px",
            backgroundColor: "#f66b0c",
            margin: "30px 0 30px 0",
          }}
        />
        <AnimateObj textH1={""} textP={""} textP2={bodySplit(body)} Img={""} />

        {id === "as400_formation" && (
          <div style={{ marginTop: "35px" }}>
            <AnimateObj
              textH1={""}
              textP={"Pour en savoir plus"}
              textP2={""}
              Img={""}
            />
            <Link to={"/Contact"}>
              <BtnNo>Contactez-nous</BtnNo>
            </Link>
          </div>
        )}
      </DetailsServ>
      <div>
        <AnimateObj
          textH1={""}
          textP={""}
          textP2={""}
          Img={`../images/servicesDetails/${image}`}
          Width={"450px"}
        />
      </div>
    </Flex>
  );
}
