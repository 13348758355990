import React,{useEffect,useState} from 'react'
import { Pop,Btn } from '../components/styles/Services.styled';
import { motion,useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import ModalContact from './modal';

const ContactPop = () => {
  
    const control = useAnimation();
    const [ref, inView] = useInView();
    const boxVariant = {
      visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, scale: 0.8 }
    };
  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      }else {
        control.start("hidden");
      } 
    }, [control, inView]);


    /**** */

    const [modalIsOpen, setModalIsOpen] = useState(false);

    function openModal() {
      setModalIsOpen(true);
    }

    function closeModal() {
      setModalIsOpen(false);
    }

    return (
      <Pop>
        <motion.div
          ref={ref}
          className="title"
          variants={boxVariant}
          initial="hidden"
          animate={control}
          transition={{ type: 'spring'}}>
          <div>
            <h1>Faisons quelque chose d'incroyable ensemble Commencez par 
                <Btn onClick={openModal}> dire bonjour </Btn>
                </h1>
          </div>
          <ModalContact onClose={closeModal} modalIsOpen={modalIsOpen}/>
        </motion.div>
      </Pop>
    )
}

export default ContactPop;