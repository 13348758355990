import React, { Component } from "react";
import Job from "../components/job";
import SearchInput from "../components/searchinput";
import AnimateObj from "../components/Framer";
import { Flex2, PostOffers } from "../components/styles/home.styled";
// import axios from "axios";
import { Container, Flex } from "../components/styles/Services.styled";
import LoadingSpinner from "../components/LoadingSpinner";
import "../components/styles/NotFound.css";
import { BlobAnimated } from "../components/SvgContainer";
import {
  AstNo,
  BtnNo,
  ContainerNo,
  FlexNo,
  Img,
  Left,
  Right,
} from "../components/styles/OffersNo.styled";
import { Link } from "react-router-dom";

export default class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      Posts: [],
    };
  }

  async getPosts(keyword, isTag) {
    try {
      this.setState({ loading: true });

      let url;

      if (!keyword) {
        // URL for fetching all posts
        url =
          "https://www.somadsi.ma/custom-offer-plugin/fetch-data.php?param=offers";
      } else {
        if (!isTag) {
          // URL for searching posts by keyword
          url = `https://www.somadsi.ma/custom-offer-plugin/fetch-data-keyword.php?keyword=${encodeURIComponent(
            keyword
          )}`;
        } else {
          url = `https://www.somadsi.ma/custom-offer-plugin/fetch-data-keyword.php?keyword=${encodeURIComponent(
            keyword
          )}&isTag=${isTag}`;
        }
      }

      const response = await fetch(url);

      // Check if the response status is OK (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      this.setState({
        loading: false,
        Posts: data,
      });
    } catch (error) {
      console.error("Error fetching posts:", error.message);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getPosts();
  }

  //desactivate right-click on objects
  handleContextMenu = (e) => {
    e.preventDefault();
  };

  search = (keyword, isTag) => {
    this.setState(
      {
        Posts: [],
        loading: true,
      },
      () => {
        this.getPosts(keyword, isTag);
      }
    );
  };

  render() {
    const { loading, Posts } = this.state;
    if (loading) {
      return <LoadingSpinner />;
    } else if (Posts.length !== 0) {
      return (
        <Container onContextMenu={this.handleContextMenu}>
          <Flex style={{ overflow: "hidden" }}>
            <div>
              <AnimateObj
                textH1={"Construisez votre carrière avec nous"}
                textP={""}
                textP2={""}
                Img={""}
              />
              <hr
                style={{
                  height: "3px",
                  width: "100px",
                  backgroundColor: "#f66b0c",
                  margin: "30px 0 30px 0",
                }}
              />
              <AnimateObj
                textH1={""}
                textP={""}
                textP2={
                  "Chez nous, nous sommes passionnés par le développement de notre équipe et la construction de carrières solides et gratifiantes pour nos employés. Nous offrons des opportunités de croissance professionnelle, des programmes de formation continue et des projets stimulants pour nos employés. Rejoindre notre entreprise, c'est avoir la possibilité de réaliser son potentiel et d'évoluer dans un environnement dynamique et en constante évolution."
                }
                Img={""}
              />
            </div>
            <div>
              <AnimateObj
                textH1={""}
                textP={""}
                textP2={""}
                Img={"images/job.gif"}
                Width={"450px"}
              />
            </div>
          </Flex>

          <SearchInput onSearch={this.search} />

          <PostOffers className="wholePage">
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <h1>Postes ouverts</h1>
              <p> vous trouverez ici toutes les offres disponibles! </p>
              <hr
                style={{
                  height: "3px",
                  width: "100px",
                  backgroundColor: "#f66b0c",
                  margin: "30px auto",
                }}
              />
            </div>
            <Flex2>
              <div style={{ margin: "0" }} className="PostScroll">
                {Posts.map((item, index) => (
                  <Job onSearch={this.search} key={index} item={item} />
                ))}
              </div>
            </Flex2>
          </PostOffers>
        </Container>
      );
    } else {
      return (
        <ContainerNo onContextMenu={this.handleContextMenu}>
          <FlexNo>
            <Left>
              <title style={{ display: "flex" }}>
                <h2>
                  {" "}
                  Offres actuellement <span> indisponibles </span>
                </h2>
              </title>
              <p> Pas d'offres diponible pour le moment </p>
              <p> Nous vous prions de réessayer ultérieurement </p>
              <Link to={"/"}>
                <BtnNo>Go Home</BtnNo>
              </Link>
            </Left>
            <Right>
              <AstNo>
                <BlobAnimated style={{}} />
                <Img src="images/astronaut.webp" />
              </AstNo>
            </Right>
          </FlexNo>
        </ContainerNo>
      );
    }
  }
}
