import styled from 'styled-components'


export const Container = styled.div `
    position: relative;
    width: 100%;

    html::-webkit-scrollbar-track {
        background-color: black;
      }
    
      html::-webkit-scrollbar-thumb {
        background: #4e4e4e;
        border-radius: 25px;
      }
  
      @media screen and (max-width:768px){
          overflow-x: hidden;
          margin-top: -8vh;
      }
`

export const Flex = styled.div `
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10%;
    height: 111vh;
    left: 0;
    width: 100%;
    background: ${({bg}) => bg || 'url("../../images/back.png")'};
    //background-size: auto 120%;
    opacity: 0,3;
    overflow: auto;
    margin-top: -10vh; 
    &::-webkit-scrollbar {
        display: none;
    }

    img{
        width: 50%;
        text-align: center; 
    }

    & > div {
        flex : 1;
    }
    
    p{
        font-size: 100%;
    }

    @media screen and (max-width:768px){
        padding-top: 25vh;
        margin-top:-20px;
        display: block;
        text-align: -webkit-center;
        background-image: url("../../images/back.png");
        //background-size: auto 150%; 

        h1{
            font-size: 125%;
        }
        p{
            font-size: 70%;
        }
        img{
            margin: 10%;
            width: 40%;
        }
        
    }
`


export const Para = styled.p `
    color: #413F42;
    font-weight: 400;
    margin-top: -15px; 
`

export const H1 = styled.h1 `
    margin-bottom: 10%;
`


export const FlexInline = styled.div `
    display: block;
    align-items: center;
    padding: 5vw;
    margin: 0;
    left: 0;
    background: ${({bg}) => bg || 'url("../../images/back.png")'};
    //background-size: auto 30%;
    opacity: 0,3;
`

export const StyledFlex = styled.div `
    position: relative;
    display: flex;
    flex-direction: ${({layout})=>layout || 'row'};
    width: 79vw;
    height: 20rem;
    min-height: 24rem;
    margin: 2vh 10vh;
    border: solid 1px gray;
    background-color: #F2F2F2;
    padding: 5vh;
    border-radius: 10px; 
    @media screen and (max-width:768px){
        display: grid;
        padding: 2vw;
        margin: 3vw;
    }
`

export const CardContainer = styled.div `
    display: flex;
    justify-content: center;
`

export const Img = styled.img `
    width: 200px;
`

export const FlexSection = styled.div `
    display: table-cell;
    margin-right: 5vw; 
    width: 40vw;
    align-content: center;
    align-items: center; 
    align-self: center;
    @media screen and (max-width:768px){
        text-align: center; 
        padding: 3vw;
        width: 100%;

        h1{
            font-size: 25px; 
        }
        p{
            font-size: 12px;
        }

        img{
            width:20%;
        }
    }
`