import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav, NavbarBrand } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import { useState, useEffect } from "react";

function Newbar() {
  const [tstyle, setTstyle] = useState({
    transform: "translateY(0px) rotate(0deg)",
  });
  const [mstyle, setMstyle] = useState({ opacity: 1 });
  const [bstyle, setBstyle] = useState({
    transform: "translateY(0px) rotate(0deg)",
  });
  const [expanded, setExpanded] = useState(false); // New state to control navbar collapse

  const Xfunction = () => {
    if (tstyle["transform"] === "translateY(0px) rotate(0deg)") {
      let copyTop = { ...tstyle, transform: "translateY(13px) rotate(135deg)" };
      setTstyle(copyTop);
      let copyBottom = {
        ...bstyle,
        transform: "translateY(-5px) rotate(-136deg)",
      };
      setBstyle(copyBottom);
      let opa = { ...mstyle, opacity: 0 };
      setMstyle(opa);
    } else if (tstyle["transform"] === "translateY(13px) rotate(135deg)") {
      let copyToo = { ...tstyle, transform: "translateY(0px) rotate(0deg)" };
      setTstyle(copyToo);
      let copyBoo = { ...bstyle, transform: "translateY(0px) rotate(0deg)" };
      setBstyle(copyBoo);
      let opa = { ...mstyle, opacity: 1 };
      setMstyle(opa);
    }

    // Close the navbar on Xfunction
    setExpanded(!expanded);
  };

  //change color background navbar when scrolling
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  // Close the navbar on link click
  const location = useLocation();

  return (
    <Navbar
      className={navbar ? "mynav active" : "mynav"}
      sticky="top"
      expand="md"
      collapseOnSelect
      expanded={expanded} // Pass expanded state to control collapse
      onContextMenu={handleContextMenu}
    >
      <NavbarBrand id="brand" style={{ zIndex: "999" }}>
        <Link className="nav-link" to="/">
          <img
            src={process.env.PUBLIC_URL + "/images/logos.webp"}
            alt="SOMADSI"
          />
        </Link>
      </NavbarBrand>

      <NavbarToggle id="navtoggle" onClick={Xfunction}>
        <span className="togglebar top-bar" style={tstyle}></span>
        <span className="togglebar middle-bar" style={mstyle}></span>
        <span className="togglebar bottom-bar" style={bstyle}></span>
      </NavbarToggle>

      <Navbar.Collapse id="collapse">
        <Nav id="nav">
          <Link
            id="link"
            className={`nav-link ${
              location.pathname === "/solutions" ? "active" : ""
            }`}
            to="/solutions"
            onClick={Xfunction}
          >
            {" "}
            Solutions{" "}
          </Link>
          <Link
            id="link"
            className={`nav-link ${
              location.pathname === "/services" ? "active" : ""
            }`}
            to="/services"
            onClick={Xfunction}
          >
            {" "}
            Services{" "}
          </Link>
          <Link
            id="link"
            className={`nav-link ${
              location.pathname === "/about" ? "active" : ""
            }`}
            to="/about"
            onClick={Xfunction}
          >
            {" "}
            À propos{" "}
          </Link>
          <Link
            id="link"
            className={`nav-link ${
              location.pathname === "/offers" ? "active" : ""
            }`}
            to="/offers"
            onClick={Xfunction}
          >
            {" "}
            Offres{" "}
          </Link>
          <Link
            id="link"
            className={`nav-link ${
              location.pathname === "/contact" ? "active" : ""
            }`}
            to="/contact"
            onClick={Xfunction}
          >
            {" "}
            Contact{" "}
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Newbar;
