import React, { useEffect } from "react";
import Slider from "../components/slider";
import homeCont from "../components/homeinfo";
import AnimateObj from "../components/Framer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Card from "../components/Card";
import contentServ from "../components/contentServices";
import Client from "../components/client";
import ContactPop from "../components/contactpop";
import {
  Titre,
  Rawdaw,
  CardPart,
  Questions,
  HomeFlex,
  ContainerHome,
} from "../components/styles/home.styled";
import { Link } from "react-router-dom";
import { BtnMore } from "../components/styles/Services.styled";

function Home() {
  const [ref, inView] = useInView();
  const control = useAnimation();

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <ContainerHome onContextMenu={handleContextMenu}>
      <Slider />
      <HomeFlex className="firstinfo">
        <Questions>
          {homeCont.map((item, index) => (
            <Rawdaw
              className="row"
              layout={item.id % 2 === 0 && "row-reverse"}
              key={index}
            >
              <Titre className="col title">
                <AnimateObj
                  textH1={item.title}
                  textP={""}
                  textP2={""}
                  Img={""}
                />
              </Titre>
              <hr
                style={{
                  height: "3px",
                  width: "100px",
                  color: "#000",
                  marginTop: "50px",
                  transform: "rotate(90deg)",
                }}
              />
              <div className="col">
                <AnimateObj
                  textH1={""}
                  textP={item.body}
                  textP2={""}
                  Img={""}
                />
              </div>
            </Rawdaw>
          ))}
        </Questions>
      </HomeFlex>
      <div className="cards bg-light p-1">
        <div className="div titlediv">
          <h1 style={{ marginBottom: "30px" }}>Certains de nos services</h1>
          <hr
            style={{
              height: "3px",
              width: "100px",
              backgroundColor: "#f66b0c",
              margin: "auto",
            }}
          />
        </div>
        <motion.div
          ref={ref}
          className="title"
          variants={boxVariant}
          initial="hidden"
          animate={control}
          transition={{ type: "spring" }}
        >
          <CardPart>
            {contentServ.map((item, index) => (
              <Card key={index} item={item} />
            ))}
          </CardPart>
        </motion.div>
        <Link to="/solutions">
          <BtnMore style={{ marginTop: "0px", marginBottom: "50px" }}>
            En savoir plus
          </BtnMore>
        </Link>
      </div>
      <Client />
      <hr style={{ height: "3px" }} />
      <ContactPop />
    </ContainerHome>
  );
}

export default Home;
