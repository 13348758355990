import styled from "styled-components";

export const ContainerHome = styled.div`
  position: relative;
  width: 100%;

  html::-webkit-scrollbar {
    width: 20px;
  }

  html::-webkit-scrollbar-track {
    background-color: black;
  }

  html::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 25px;
  }

  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    margin-top: -8vh;
  }
`;

export const Rawdaw = styled.div`
  display: flex;
  flex-direction: ${({ layout }) => layout || "row"};

  @media screen and (max-width: 768px) {
    display: inline;

    & > div {
      padding: 20px;
    }
    & > hr {
      display: none;
    }
  }
`;

export const Flex2 = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 100px;
  height: 100vh;
  left: 0;
  width: 100%;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em #f66b0c;
  border-radius: 10px;
  opacity: 0, 3;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
    height: 100vh;
    display: inline;
    & > nav {
      margin-bottom: 50px;
    }
    & > hr {
      display: none;
    }
  }
`;

export const Slider = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 35%;
    display: grid;

    & > h3 {
      font-size: 25px;
    }
    & > h5 {
      font-size: 15px;
    }
  }
`;

export const Video = styled.video``;

export const HeroVideo = styled.div`
  span {
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: -10vh;
    span {
      display: flex;
      background-image: url(images/backNotFound.png);
      background-repeat: repeat;
      height: 105vh;
    }
    video {
      display: none;
    }
  }
`;

export const ConSlider = styled.div`
  margin-top: -80px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const Titre = styled.div`
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: small;
    margin-top: 50px;
  }
`;

export const CardPart = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0 0 0 30px;
  }
`;

export const PostOffers = styled.div`
  padding: 30px;
  background-color: #f7f7f7;
`;

export const Questions = styled.div`
  overflow: hidden;

  @media screen and (max-width: 786px) {
    overflow-y: hidden;
    overflow-x: auto;
    p {
      font-size: 95%;
    }
  }
`;

export const HomeFlex = styled.div`
  padding: 7%;
  height: 111vh;
  width: 100%;
  background: ${({ bg }) => bg || 'url("../../images/back.png")'};
  //background-size: auto 120%;
  opacity: 0, 3;
  overflow: auto;
  overflow-y: hidden;
  margin: 0px;
  img {
    width: 80%;
  }

  & > div {
    flex: 1;
  }
  h1 {
    font-size: 200%;
    font-weight: bold;
  }
  p {
    font-size: 100%;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    text-align: -webkit-center;
    background-image: url("../../images/back.png");
    margin-top: 30vh;

    h1 {
      font-size: 125%;
    }
    p {
      font-size: 70%;
      font-weight: 500;
    }
    img {
      margin: 10%;
      width: 40%;
    }
    hr {
    }
  }
`;
