import React from "react";
import "./App.css";
import Newbar from "./components/newbar";
import Footer from "./components/footer";

import Home from "./pages/home";
import About from "./pages/about";
import Solutions from "./pages/solutions";
import Services from "./pages/services";
import Offers from "./pages/offers";
import Contact from "./pages/contact";
import ServicesDetails from "./pages/ServicesDetails";

import {
  Route,
  Routes,
  BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import JobPage from "./pages/jobPage";
import ScrollToTop from "./components/ScrollToTopPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Newbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/offers/:id" element={<JobDetails />} />
        <Route path="/services/:id" element={<Child />} />
      </Routes>
      <Footer />
    </Router>
  );
}

function Child() {
  let { id } = useParams();
  console.log(id);
  return <div>{<ServicesDetails idServ={id} />}</div>;
}

function JobDetails() {
  let { id } = useParams();
  console.log(id);
  return <div>{<JobPage idPost={id} />}</div>;
}

export default App;
