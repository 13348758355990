const contentServ = [
  {
    id: "as400_formation",
    num: 1,
    title: "Formation en Solution AS400",
    body: "Découvrez la solution AS400 à travers une formation complète, de la gestion des données à la programmation avancée",
    image: "formation.webp",
  },

  {
    id: "as400_solution_development",
    num: 2,
    title: "Développement de solution AS400",
    body: "Une équipe hautement qualifiée pour accompagnement dans les projets AS400",
    image: "as400.webp",
  },

  {
    id: "commercial_management",
    num: 3,
    title: "Gestion Commerciale PRO",
    //body: 'Quick creation of quotes, invoices and receipts (Orders, Deliveries, Receipts). §Possibility to save vouchers in PDF format. §Possibility to print it on letterhead or normal paper.',
    body: "Création rapide des devis, factures et bons de (Commande, Livraisons, réceptions).§Possibilité de sauvegarder les bons sous format PDF.§Possibilité de l’imprimer sur papier entête ou normal.",
    image: "commercial.webp",
  },

  {
    id: "balance_sheet_management",
    num: 4,
    title: "Gestion Bilan PRO",
    //body: 'Edition of summary statements (Active; Passive, CPC, etc.).§Processing of the balance sheet from:§Our professional accounting.',
    body: "Edition des états de synthèse (Actif ; Passif, CPC …).§Traitement du bilan à partir :§ De notre comptabilité pro.§ Importation d’une balance simple et rapide la génération EDI.",
    image: "bilan.webp",
  },

  {
    id: "accounting_management",
    num: 5,
    title: "Gestion Comptabilité PRO",
    //body: 'Its fast, easy and secure.§Save time with simple and fast accounting.§Easy creation of customers, suppliers, VAT, accounts and journals.',
    body: "C’est rapide, facile et sécurisé.§Gagner du temps avec une comptabilité simple et rapide.§Création facile des clients, fournisseurs, tva, comptes et journaux.",
    image: "comptabilite.webp",
  },

  {
    id: "cash_management",
    num: 6,
    title: "Gestion Trésorerie PRO",
    //body: 'The entry is simple and fast of Invoices Purchases, sales, Payment, Collection.§Possibility to have statistics per account.',
    body: "La saisie est simple et rapide des Factures Achats, ventes, Règlement, Encaissement.§Possibilité d’avoir des statistiques par compte.",
    image: "tresorerie.webp",
  },

  {
    id: "vat_declaration_management",
    num: 7,
    title: "Gestion Déclaration TVA PRO",
    //body: 'Treatment of VAT from:§Our professional accounting.§Our Pro Treasury.§Importing an Excel file.§Manual entry.',
    body: "Traitement de la TVA à partir : § De notre comptabilité pro.§ De notre Trésorerie pro.§ Importation d’un fichier Excel.§ Saisie manuel.",
    image: "tva.webp",
  },
];

export default contentServ;
