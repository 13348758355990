import React from "react";
import {
  StyledCard,
  CardContainer,
} from "../components/styles/Sections.styled";
import { Link } from "react-router-dom";
import { ArrowRight } from "./SvgContainer";

function bodySplit(body) {
  var brk = body.split("§");
  var res = brk.join("\n");
  return res;
}

export default function Card({ item: { id, title, body, image } }) {
  return (
    <CardContainer>
      <StyledCard>
        <img width="20%" src={`./images/services/${image}`} alt={id} />
        <div>
          <h1>{title}</h1>
          <p>{bodySplit(body)}</p>
          <Link to={"/services/" + id}>
            Learn More&nbsp;&nbsp;&nbsp; &nbsp;
            <ArrowRight />
          </Link>
        </div>
      </StyledCard>
    </CardContainer>
  );
}
