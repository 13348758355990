import React, { Component } from "react";
import { BiSearchAlt } from "react-icons/bi";
import {
  Bar,
  Container,
  OptionContainer,
  SearchBar,
  SearchButton,
} from "./styles/Offers.styled";

export default class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tags: [],
      Offers: [],
      search: "",
      tag: "",
      isTag: 0,
    };
  }

  setSearch = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  async getTags() {
    try {
      const response = await fetch(
        "https://www.somadsi.ma/custom-offer-plugin/fetch-tags.php"
      );
      const responseData = await response.json();

      // Access the array of tags correctly from responseData.data
      const tags = responseData.data || [];

      this.setState({
        Tags: tags,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  componentDidMount() {
    this.getTags();

    const storedTag = localStorage.getItem("selectedTag");
    if (storedTag) {
      this.setState({
        tag: storedTag,
      });
    }
  }

  doSearch = (event) => {
    event.preventDefault();
    const keyword =
      this.state.search.charAt(0).toUpperCase() + this.state.search.slice(1);
    if (!keyword) {
      this.props.onSearch(this.state.tag);
    } else {
      this.props.onSearch(keyword);
    }
  };

  handleSelectValue = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected Value:", selectedValue);

    this.setState(
      {
        tag: selectedValue,
      },
      () => {
        // Save the selected tag in local storage
        localStorage.setItem("selectedTag", selectedValue);

        // Trigger the search when a value is selected
        this.props.onSearch(selectedValue, 1);
      }
    );
  };

  render() {
    console.log("Selected Tag:", this.state.tag);
    if (Array.isArray(this.state.Tags) && this.state.Tags.length !== 0) {
      return (
        <Container>
          <div style={{ marginTop: "10px" }}>
            <form onSubmit={this.doSearch}>
              <SearchBar>
                <input
                  type="text"
                  onChange={this.setSearch}
                  value={this.state.search}
                  placeholder="Rechercher des mots-clés.."
                />
                <SearchButton onClick={this.doSearch}>
                  <BiSearchAlt />
                </SearchButton>
              </SearchBar>

              <Bar />
              <OptionContainer>
                <select
                  onChange={this.handleSelectValue}
                  value={this.state.tag} // Set the selected value
                >
                  <option />
                  {this.state.Tags.map((items, index) => (
                    <option key={index}>{items}</option>
                  ))}
                </select>
              </OptionContainer>
            </form>
          </div>
        </Container>
      );
    } else {
      return (
        <div>
          <h4>Pas d'offres disponibles</h4>
        </div>
      );
    }
  }
}
