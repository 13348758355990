import React,{useEffect} from 'react'
import { Clt,ImgContainer,ImgClt } from './styles/Services.styled';
import { motion,useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";

const Client = () => {
    const cltBrand = [
      {
        id: 'Axa',
        title: 'AXA',
        image: 'axa.webp',
      },
      {id: 'bea',
      title: 'Bea Concept',
      image: 'bea.webp',
      },
      {
        id: 'ofppt',
        title: 'OFPPT',
        image: 'ofppt.webp',
      },
      {
        id: 'ibc',
        title: 'International Business Consulting',
        image: 'ibc.webp',
      }
    ];

    const control = useAnimation();
    const [ref, inView] = useInView();
    const boxVariant = {
      visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, scale: 0.8 }
    };
  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      }else {
        control.start("hidden");
      } 
    }, [control, inView]);

    return (
      <Clt bg={"url('../../images/back2.webp')"} style={{ backgroundSize: 'cover'}}>
        <motion.div
          ref={ref}
          className="title"
          variants={boxVariant}
          initial="hidden"
          animate={control}
          transition={{ type: 'spring'}}>
          <div>
              <h1>Nos clients</h1>
              <p>Nous ne voulons pas imposer nos idées aux clients,</p>
              <p>nous voulons simplement faire ce qu'ils veulent.</p>
          </div>
          <ImgContainer>
          {cltBrand.map((item,index)=>(
            <div key={index}>
              <ImgClt src={`../images/clients/${item.image}`} alt={item.title}/>
            </div>
          ))}
          </ImgContainer>
        </motion.div>
      </Clt>
    )
}

export default Client;