import React, { useState } from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { BtnMore } from "../components/styles/BtnMoreStyled";
import { Container } from "./styles/JobDetails.styled";
import { Toast, notify, notifyErr } from "../components/Toast";
import { useFormik } from "formik";
import * as yup from "yup";
import emailjs from "emailjs-com";
import axios from "axios";

export default function FormExample(props) {
  // const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("Attach File");

  const titre = props.offre;
  const direction = props.direction;

  const formik = useFormik({
    validationSchema: yup.object().shape({
      nom_prenom: yup.string().required(),
      email: yup.string().required(),
      city: yup.string().required(),
      phone: yup.string().required(), // Changed from number to string
      description: yup.string().required(),
      file: yup.mixed().required(),
    }),
    initialValues: {
      nom_prenom: "",
      email: "",
      city: "",
      phone: "",
      description: "",
      offre: titre,
      filepath: "",
      file: null,
    },
    onSubmit: async (values) => {
      try {
        // Use Axios to upload the file to the PHP endpoint
        const uploadResponse = await uploadFileToServer(values.file);

        if (uploadResponse.status === "success") {
          // If upload is successful, use the obtained file link
          const fileLink = uploadResponse.fileLink;

          const emailServiceId = "service_mhw9p2r";
          const templateId = "template_uzhsxvd";
          const userId = "NphJ9IyJ5k6e2IqWq";

          const emailParams = {
            to_email: "assistant@somadsi.ma",
            subject: "Nouvelle demande d’emploi",
            nom_prenom: values.nom_prenom,
            email: values.email,
            offre: titre,
            file: fileLink,
          };

          const emailResponse = await emailjs.send(
            emailServiceId,
            templateId,
            emailParams,
            userId
          );

          console.log(emailResponse);

          const newApplicant = {
            ...values,
            filepath: fileLink,
          };

          const createApplicantUrl =
            "https://www.somadsi.ma/custom-offer-plugin/create-applicant.php";

          await fetch(createApplicantUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newApplicant),
          });

          notify("top-center");
        } else {
          // Handle file upload error
          console.error("File upload failed:", uploadResponse.message);
          notifyErr(
            "Désolé, votre demande n'a pas été envoyée avec succès. Veuillez réessayer plus tard",
            "top-center"
          );
        }
      } catch (error) {
        console.error("Form submission error:", error);
        notifyErr(
          "Désolé, votre demande n'a pas été envoyée avec succès. Veuillez réessayer plus tard",
          "top-center"
        );
      }
    },
  });

  const onChange = (e) => {
    setFilename(e.target.files[0]?.name || "Attach File");
    formik.setFieldValue("file", e.target.files[0]);
  };

  const uploadFileToServer = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `https://www.somadsi.ma/custom-offer-plugin/upload.php?direction=${direction}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("File upload to server failed", error);
      throw error;
    }
  };

  return (
    <Container>
      <Form
        noValidate
        encType="multipart/form-data"
        onSubmit={formik.handleSubmit}
      >
        <Row style={{ marginBottom: "-40px" }}>
          <Form.Group
            as={Col}
            md="6"
            controlId="validationFormik101"
            className="position-relative"
          >
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>Nom Complet :</h6>
            </Form.Label>
            <Form.Control
              type="text"
              name="nom_prenom"
              placeholder="Nom et Prenom"
              value={formik.values.nom_prenom}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={formik.touched.nom_prenom && !formik.errors.nom_prenom}
              isInvalid={
                formik.touched.nom_prenom && !!formik.errors.nom_prenom
              }
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.nom_prenom}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationFormikEmail">
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>E-Mail :</h6>
            </Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Entrer votre email.."
                aria-describedby="inputGroupPrepend"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {formik.errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "-40px" }}>
          <Form.Group
            as={Col}
            md="6"
            controlId="validationFormik103"
            className="position-relative"
          >
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>Ville :</h6>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Ville"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={formik.touched.city && !formik.errors.city}
              isInvalid={formik.touched.city && !!formik.errors.city}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.city}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            controlId="validationFormik104"
            className="position-relative"
          >
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>Téléphone :</h6>
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="Téléphone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={formik.touched.phone && !formik.errors.phone}
              isInvalid={formik.touched.phone && !!formik.errors.phone}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "-40px" }}>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationFormik103"
            className="position-relative"
          >
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>Petite auto-déscription :</h6>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Ecrivez une brève description de vous-même.."
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={
                formik.touched.description && !!formik.errors.description
              }
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.description}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "-50px" }}>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationFormik103"
            className="position-relative mb-4"
          >
            <Form.Label style={{ padding: "10px 0" }}>
              <h6>Téléchargez votre CV :</h6>
            </Form.Label>
            <Form.Control
              type="file"
              required
              name="file"
              placeholder={filename}
              onChange={onChange}
              isValid={formik.touched.file && !formik.errors.file}
              isInvalid={formik.touched.file && !!formik.errors.file}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.file}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <BtnMore type="submit">Postulez maintenant</BtnMore>
          <Toast />
        </Row>
      </Form>
    </Container>
  );
}
