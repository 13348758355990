import styled from 'styled-components'

export const Container = styled.div `
    margin: 60px 0;
    @media screen and (max-width:768px){
        display: flex;
        margin: 0;
        width: 100%;
        max-width: 100%;
        padding: 1%;
        padding-top: 10%; 
    }
`


export const PostCard = styled.div `


    @media screen and (max-width:768px){
        padding: 0px;
        h2{
            font-size: large;
        }
    }
`