import styled from "styled-components";

export const Modal = styled.div`
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    align-items:center;
    justify-content:center;

    @media screen and (max-width:768px){
        padding-top: 50px;
        width:100%;
        height: 100%;
        background-color:#fff;
        border-radius: 0;
        display: table;
        align-items:center;
        justify-content: space-between;
        overflow-y: hidden;
    }
`

export const ModalContent = styled.div`
    width:140vh;
    background-color:#fff;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    height: 390px;
    
    @media screen and (max-width:500px){
        width:100%;
        height: 100%;
        padding-top: 20%;
        background-color:#fff;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        padding-left : 5px;
    }

    @media screen and (max-width:768px){
        width:74vh;
        height: 450px;
    }

    @media screen and (max-width:1000px) and (min-width: 768px) {
        width:110vh;
        height: 390px;
    }
    
`

export const ModalHeader = styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
` 

export const ModalTitle = styled.h3`
    margin: 0;
    font-weight: 600;
`

export const ModalBody = styled.div`
    padding:10px;
`

export const ModalFooter = styled.div`
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const BtnExit = styled.div`
    background: #D0C9C0;
    width: 25px;
    height: 25px;
    border-radius:50%;
    cursor: pointer;
    font-weight : 800;
    text-align : center;
    font-size: 80%;
    padding-top : 10%;
    color: grey;
    &:hover{
        color: #f66b0c;
        background: #7F8487;
        transform: translate(0%);
        transition: 0.2s ease-out;
    }

    img{
        width: 11px;
        height: 10px;
        font-size: 20px;
    }

    @media screen and (max-width:768px){
        margin: -50px;
        margin-top: -80px;
        
        img{
            margin-top: 3px;
            width: 11px;
            height: 10px;
        }
    }

    @media screen and (min-width:1000px){
        font-size: 90%;
        padding-top : 4%;
    }

`

export const BtnAttach = styled.input`
    display: flex;
    width: 0px;
    text-align: center;
    text-decoration: none;
    color: black;
`

export const BtnSubmit = styled.button`
    display: flex;
    color:white;
    width: 120px;
    background: #f66b0c;
    border: 0px;
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 2px;
    margin-left: 15%;
    margin-right: 21px;
    &:hover {
        background-color: black;
        transition: 0.2s ease-out;
        border-radius: 1px 10px;
        box-shadow: grey 2px 2px 2px; 
    }   
`

export const Input = styled.input`
    padding: 5px;
    width: 189px;
    margin : 0 30px 20px 0;
    border: solid 1px #F1EEE9;
    border-radius : 1px;
    outline: none;

    @media screen and (max-width:768px){
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 15px;
    }

    @media screen and (max-width:1000px) and (min-width: 768px) {
        width: 100vh;
        margin-right: 16px; 
    }

    @media screen and (min-width:1000px){
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 18px;
    }
`

export const EreaText = styled.textarea`
    padding: 5px;
    width: 96.5%;
    height: 100px;
    outline: none;
    border-radius : 1px;
    border: solid 1px #F1EEE9;
    
    @media screen and (max-width:500px){
        width: 41vh;
    }
`


export const ModalImg = styled.img`
    width: 120%;
    margin-top: 12.5%;
    margin : 0 0 0 -25px;
    @media screen and (max-width:500px){
        display: none;
    }

`


export const ModalHero = styled.div`
    display: inline-block;
`


export const ModalContactContainer = styled.div`
    width: 70vh;

    @media screen and (max-width:500px){
        display: block;
        height: 100vh;
        width: 100vw;
        
    }

    @media screen and (min-width:1000px){
        width: 80vh;
        padding-left: 8%;
    }
    
`

export const InputContainer = styled.div`
    display: flex;

    @media screen and (max-width:768px){
        display: flex;
        flex-wrap: wrap;
    }
`

export const Form = styled.form`
    display: contents;
    text-indent: 6px;
`

export const Label = styled.label`
    display: contents;
    cursor: pointer;
    margin-bottom: -0.3rem; 
    font-size: .96rem; 
    .attach-file{
        margin-right: 10px; 
    }
    &:hover{
        color: #f66b0c;
        transition: 0.2s ease-out;
        .attach-file{
            fill: #f66b0c;
            transition: 0.2s ease-out;
        }
    }
`