import React,{useEffect} from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import { PersonCard, WordCard } from './styles/Services.styled';


function Word() {
   
    const control = useAnimation();
    const [ref, inView] = useInView();
    const boxVariant = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0.8 }
    };

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <WordCard bg="url('../../images/backWord.webp')">

            <motion.div
                ref={ref}
                className="title"
                variants={boxVariant}
                initial="hidden"
                animate={control}
                transition={{ type: 'spring' }} 
                style={{marginTop: '-5%'}}>

                <PersonCard>
                    <img src='../images/circle_pic.png' alt='' style={{ width: '130px',margin:'30px' }} />
                    <p style={{ }}>Bonjour, en tant que gérant de SOMADSI, je suis fier de dire que notre société est un fournisseur de services en technologies de l'information de premier plan. Notre équipe de professionnels hautement qualifiés possède une expertise technique approfondie et une connaissance approfondie des besoins des clients, nous permettant de fournir des solutions informatiques personnalisées pour répondre aux besoins uniques de chaque client. Nous sommes engagés à offrir des services de haute qualité pour répondre aux exigences des projets informatiques les plus complexes, tout en assurant une satisfaction totale de nos clients. Chez SOMADSI, nous sommes passionnés par l'innovation technologique et nous nous efforçons de rester à la pointe de l'industrie pour offrir les meilleures solutions possibles à nos clients.</p>
                    <h5 style={{ margin: '30px' }}><strong>Youssef CHARRADI</strong> <span style={{ color: 'orange' }}>- Gérant, SOMADSI</span></h5>
                </PersonCard>

            
            </motion.div>

        </WordCard>
       
    )
}

export default Word
