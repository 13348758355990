import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "../components/styles/Footer.styled";
import { FaFacebook, FaLinkedin, FaPhoneSquareAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import "../pages/switch.css";

export default function Footer() {
  //desactivate right-click on objects
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Box onContextMenu={handleContextMenu}>
      <Container style={{ fontFamily: "big Vesta, sans-serif" }}>
        <Row>
          <Column style={{ marginRight: "30px" }}>
            <Heading style={{ fontWeight: "700" }}>
              <img
                src="./images/logos.webp"
                alt=""
                style={{ width: "150px" }}
              />
            </Heading>
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
              }}
            />

            <p>
              Société marocaine de développement & système d'information.
              SOMADSI se classe parmi les fournisseurs de services en IT,
              l’équipes de SOMADSI maîtrisent la complexité des projets
              applicatifs et mettent en œuvre les solutions les mieux adaptées
              aux besoins des clients.
            </p>
          </Column>
          <Column>
            <Heading style={{ fontWeight: "700" }}>Services</Heading>
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
              }}
            />

            <FooterLink href="/services/as400_formation">
              Formation en solution AS400
            </FooterLink>
            <FooterLink href="/services/as400_solution_development">
              Développement de solution AS400
            </FooterLink>
            <FooterLink href="/services/accounting_management">
              Gestion Comptabilité PRO
            </FooterLink>
            <FooterLink href="/services/commercial_management">
              Gestion Commerciale PRO
            </FooterLink>
            <FooterLink href="/services/cash_management">
              Gestion Trésorerie PRO
            </FooterLink>
            <FooterLink href="/services/balance_sheet_management">
              Gestion Bilan PRO
            </FooterLink>
            <FooterLink href="/services/vat_declaration_management">
              Gestion Déclaration TVA PRO
            </FooterLink>
          </Column>
          <Column>
            <Heading style={{ fontWeight: "700" }}>Contact Us</Heading>
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
              }}
            />

            <FooterLink href="tel:+212678053461">
              <FaPhoneSquareAlt /> +(212) 678 053 461
            </FooterLink>
            <FooterLink href="mailto:info@somadsi.ma">
              <IoMdMail /> info@somadsi.ma
            </FooterLink>
            <FooterLink href="mailto:assistant@somadsi.ma">
              <IoMdMail /> assistant@somadsi.ma
            </FooterLink>
          </Column>
          <Column>
            <Heading style={{ fontWeight: "700" }}>Company</Heading>
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
              }}
            />

            <FooterLink href="/solutions">Solutions</FooterLink>
            <FooterLink href="/services">Services</FooterLink>
            <FooterLink href="/about">À propos de nous</FooterLink>
            <FooterLink href="/offers">Offres</FooterLink>
            <FooterLink href="/contact">Contact</FooterLink>
            <FooterLink href="/offers">
              Construisez votre carrière avec nous
            </FooterLink>
          </Column>
          <Column style={{ marginBottom: "20px" }}>
            <Heading style={{ fontWeight: "700" }}>Suivez-nous</Heading>
            <hr
              style={{
                height: "3px",
                width: "100px",
                backgroundColor: "#f66b0c",
              }}
            />

            <FooterLink
              href="https://www.facebook.com/SOMADSI-101215478486277"
              target="_blank"
            >
              <FaFacebook />
              <span style={{ marginLeft: "10px" }}>Facebook</span>
            </FooterLink>
            <FooterLink
              href="https://www.linkedin.com/company/somadsi/"
              target="_blank"
            >
              <FaLinkedin />
              <span style={{ marginLeft: "10px" }}>LinkedIn</span>
            </FooterLink>
          </Column>
        </Row>
      </Container>
    </Box>
  );
}
