import React,{ useEffect }  from 'react'
import { StyledFlex,CardContainer,Img,FlexSection } from '../components/styles/Solutions.styled'
import { BtnMore } from '../components/styles/Services.styled'
import {Link} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion,useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";

function bodySplit(body) {
  var brk = body.split('§');
  var res = brk.join('\n');
  return res;
}

export default function InlineCard({item:{ id, num, title, body, image}}) {


  const control = useAnimation();
  const [ref, inView] = useInView();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 }
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }else {
      control.start("hidden");
    } 
  }, [control, inView]);

  return (
    <motion.div
    ref={ref}
    className="title"
    variants={boxVariant}
    initial="hidden"
    animate={control}
    transition={{ type: 'spring'}}>
      <CardContainer>
        <StyledFlex layout={num % 2 === 0 && 'row-reverse'}>
          <FlexSection>
            <h1>{title}</h1>
            <p>{bodySplit(body)}</p>
              <Link to={'/services/' + id}><BtnMore>En savoir plus sur les services</BtnMore></Link>
          </FlexSection>
          <FlexSection style={{textAlign:'center'}}>
              <Img src={`././images/solutions/${image}`} alt="Card image cap"/>
          </FlexSection>
        </StyledFlex>
      </CardContainer>
    </motion.div>
  )
}


