import React from 'react';
import '../components/styles/LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
        <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;
