const bodyCards = [
  {
    id: "as400_formation",
    title: "Formation en Solution AS400",
    body: "Découvrez notre formation spécialisée sur la solution AS400, conçue pour vous offrir une expertise complète dans ce système informatique emblématique.§§Guidés par des experts chevronnés, vous explorerez en profondeur les fonctionnalités, la gestion des données et la programmation avancée sur la plateforme AS400.§§Que vous soyez un professionnel de l'informatique souhaitant renforcer ses compétences ou une entreprise cherchant à optimiser ses opérations, cette formation vous fournira les connaissances et les outils nécessaires pour réussir dans un environnement AS400.",
    image: "formation_as400.webp",
  },

  {
    id: "as400_solution_development",
    title: "Développement de solution AS400",
    body: "Nous sommes une entreprise spécialisée dans le développement de solutions sur AS400, avec une équipe hautement qualifiée de développeurs expérimentés.§§Nous proposons des services de développement sur AS400, avec une approche personnalisée pour répondre aux besoins spécifiques de chaque client.§§Notre équipe de développeurs AS400 est certifiée et dispose d'une expertise approfondie dans les langages de programmation tels que RPG, COBOL et Java.§§Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et objectifs, et nous fournissons des solutions sur mesure pour répondre à leurs exigences.§§Nous avons une solide expérience dans le développement de solutions sur AS400 pour divers secteurs, tels que les services financiers, la logistique, la vente au détail et la fabrication.§§Nous utilisons les dernières technologies et outils de développement pour fournir des solutions innovantes et fiables sur la plateforme AS400.",
    image: "as400.gif",
  },

  {
    id: "commercial_management",
    title: "Gestion Commerciale PRO",
    //body: 'Quick creation of quotes, invoices and receipts (Orders, Deliveries, Receipts).§Possibility to save vouchers in PDF format. §Possibility to print it on letterhead or normal paper. §Existence of an invoice deposit slip.§Stock monitoring with alert option if the threshold is exceeded.§Product statistics.§Statistics on turnover and expenses.',
    body: "Création rapide des devis, factures et bons de (Commande, Livraisons, réceptions).§Possibilité de sauvegarder les bons sous format PDF.§Possibilité de l’imprimer sur papier entête ou normal.§Existence d’un bordereau de dépôt de facture.§Suivie du stock avec option d’alerte si dépassement du seuil.§Statistique sur les produits.§Statistique sur chiffre d’affaire et les dépenses.",
    image: "commercial.gif",
  },

  {
    id: "balance_sheet_management",
    title: "Gestion Bilan PRO",
    //body: 'Edition of summary statements (Active; Passive, CPC, etc.).§Processing of the balance sheet from:§From our professional accounting.§Importing a scale.§Simple and fast EDI generation.§Possibility of having the balance sheet in PDF format.',
    body: "Edition des états de synthèse (Actif ; Passif, CPC …).§Simple et rapide la génération EDI. §Possibilité d’avoir le bilan sous format PDF.§Traitement du bilan à partir :§  De notre comptabilité pro.§  Importation d’une balance.",
    image: "bilan.gif",
  },

  {
    id: "accounting_management",
    title: "Gestion Comptabilité PRO",
    //body: 'Its fast, easy and secure.§Save time with simple and fast accounting.§Easy creation of customers, suppliers, VAT, accounts and journals.§Lettering of accounts in a very simple way and without difficulty in case of difference.§Consultation of instantaneous movements by account by exercise or by period.§Standard ledger, balance and statement editions of journals and other online editions.§Designed at the request of our customers.§It is a purely Moroccan software designed with specialists in the field.§We enrich it as and when extra requests from our customers.',
    body: "C’est rapide, facile et sécurisé.§Gagner du temps avec une comptabilité simple et rapide.§Création facile des clients, fournisseurs, tva, comptes et journaux.§Lettrage des comptes d’une manière très simple et sans difficulté en cas de différence.§Consultation des mouvements instantané par compte par exercice ou par période.§Les éditions standards grands livre, balance et état des journaux et d’autres éditions en lignes.§Conçu à la demande de nos clients.§C’est un logiciel purement marocain conçu avec des spécialistes dans la matière.§On l’enrichi au fur et à mesure des demandes extras de nos clients.",
    image: "comptabilite.gif",
  },

  {
    id: "cash_management",
    title: "Gestion Trésorerie PRO",
    //body: 'The entry is simple and fast of Invoices Purchases, sales, Payment, Collection.§Possibility to have statistics per account.§Possibility of having the situation per account per month.',
    body: "La saisie est simple et rapide des Factures Achats, ventes, Règlement, Encaissement.§Possibilité d’avoir des statistiques par compte. §Possibilité d’avoir la situation par compte par mois.",
    image: "tresorerie.gif",
  },

  {
    id: "vat_declaration_management",
    title: "Gestion Déclaration TVA PRO",
    //body: 'Treatment of VAT from:§From our professional accounting.§From our Pro Treasury.§Importing an Excel file.§Manual entry§Simple and fast EDI generation.§Edit summary report to facilitate entry.',
    body: "Génération EDI simple et rapide.§Edition état récapitulatif pour faciliter la saisie.§Traitement de la TVA à partir :§   De notre comptabilité pro.§   De notre Trésorerie pro.§   Importation d’un fichier Excel.§   Saisie manuel.",
    image: "tva.gif",
  },
];

export default bodyCards;
