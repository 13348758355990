import React from "react";

export const ArrowRight = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill={fill}
      className="bi bi-arrow-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  );
};

export const AttachFile = ({ fill }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 15 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className="attach-file"
    >
      <path d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z"
      />
    </svg>
  );
};

export const BlobAnimated = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width="100%"
      id="blobSvg"
      style={{
        opacity: "1",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      filter="blur(qs0px)"
      transform="rotate(45)"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: "rgb(255, 102, 0)" }}></stop>
          <stop offset="100%" style={{ stopColor: "rgb(255, 102, 0)" }}></stop>
        </linearGradient>
      </defs>
      <path id="blob" fill="url(#gradient)" style={{ opacity: "1" }}>
        <animate
          attributeName="d"
          dur="20.4s"
          repeatCount="indefinite"
          values="M439.43806,316.51312Q432.44085,383.02623,370.39398,407.14258Q308.3471,431.25893,240.13979,459.78516Q171.93248,488.31139,132.30301,426.26451Q92.67355,364.21763,63.21484,307.10882Q33.75613,250,44.06194,178.90151Q54.36774,107.80301,118.67355,75.31613Q182.97935,42.82924,240.60882,72.66602Q298.23828,102.50279,368.10882,107.87054Q437.97935,113.23828,442.20731,181.61914Q446.43527,250,439.43806,316.51312Z;M423.38828,307.00416Q406.39243,364.00831,353.55217,384.57202Q300.71191,405.13573,242.06787,430.50416Q183.42382,455.87258,116.5554,425.63666Q49.68697,395.40075,58.87581,322.70037Q68.06464,250,86.66067,197.31625Q105.2567,144.63251,148.86843,108.53648Q192.48015,72.44045,252.10019,66.13989Q311.72022,59.83933,378.54063,84.21929Q445.36105,108.59925,442.87258,179.29963Q440.38412,250,423.38828,307.00416Z;M437.48517,301.93698Q392.82947,353.87396,357.50371,413.21131Q322.17795,472.54867,247.51112,479.51159Q172.8443,486.47452,129.54819,427.64458Q86.25209,368.81464,81.57785,309.40732Q76.90361,250,83.86283,191.93327Q90.82205,133.86654,141.98146,104.152Q193.14087,74.43745,247.01483,83.88926Q300.88878,93.34106,355.51112,113.62234Q410.13346,133.90361,446.13717,191.95181Q482.14087,250,437.48517,301.93698Z;M439.43806,316.51312Q432.44085,383.02623,370.39398,407.14258Q308.3471,431.25893,240.13979,459.78516Q171.93248,488.31139,132.30301,426.26451Q92.67355,364.21763,63.21484,307.10882Q33.75613,250,44.06194,178.90151Q54.36774,107.80301,118.67355,75.31613Q182.97935,42.82924,240.60882,72.66602Q298.23828,102.50279,368.10882,107.87054Q437.97935,113.23828,442.20731,181.61914Q446.43527,250,439.43806,316.51312Z"
        ></animate>
      </path>
    </svg>
  );
};
