import React, { useEffect } from "react";
import {
  Clt,
  ImgClt,
  ImgContainer,
} from "../components/styles/Services.styled";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Stack = () => {
  const cltBrand = [
    {
      id: "VB10",
      title: "vb10",
      image: "vb10.webp",
    },
    { id: "RPGLE", title: "rpg", image: "rpg.webp" },
    {
      id: "ACCESS",
      title: "access",
      image: "access.webp",
    },
    {
      id: "AS400",
      title: "as400",
      image: "as400.webp",
    },
    {
      id: "LANSA",
      title: "lansa",
      image: "lansa.webp",
    },
    {
      id: "JAVA",
      title: "java",
      image: "java.webp",
    },
    {
      id: "DB2",
      title: "db2",
      image: "db2.webp",
    },
  ];

  const control = useAnimation();
  const [ref, inView] = useInView();
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  //desactivate click sur img
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Clt bg={"#F3F3F3"}>
      <motion.div
        ref={ref}
        className="title"
        variants={boxVariant}
        initial="hidden"
        animate={control}
        transition={{ type: "spring" }}
      >
        <div>
          <h1>Notre pile technologique</h1>
        </div>
        <ImgContainer>
          {cltBrand.map((item, index) => (
            <div key={index}>
              <ImgClt
                src={`../images/stack/${item.image}`}
                alt={item.id}
                onContextMenu={handleContextMenu}
              />
            </div>
          ))}
        </ImgContainer>
      </motion.div>
    </Clt>
  );
};

export default Stack;
