import styled from "styled-components";

export const Container = styled.div`
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  height: 60px;
  position: sticky;
  top: 70px;
  z-index: 99;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 768px) {
  }
`;

export const SearchBar = styled.div`
  display: inline-block;
  input {
    height: 30px;
    padding: 20px;
    border-radius: 6px 0 0 6px;
    border: none;
    outline: none;
    width: 400px;
  }
  span {
    font-size: 25px;
    position: absolute;
    margin-left: -35px;
  }

  @media screen and (max-width: 768px) {
    input {
      width: 65vw;
    }
  }
`;

export const OptionContainer = styled.div`
  display: inline-block;
  select {
    width: 170px;
    height: 40px;
    padding: 0 8px 0 20px;
    border-radius: 0 6px 6px 0;
    border: none;
    outline: none;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    select {
      width: 125px;
      padding: 0 5px 0 10px;
    }
  }
`;

export const Bar = styled.hr`
  height: 4px;
  width: 30px;
  color: #393939;
  transform: rotate(90deg);
  position: absolute;
  margin-top: -22px;
  margin-left: 385px;

  @media screen and (max-width: 768px) {
    width: 25px;
    margin-left: 63vw;
  }
`;

export const CardOffers = styled.div`
  width: 70vw;
  margin: 30px auto;
  padding: 30px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 80vw;
    font-weight: 500;
  }
`;

export const NoOffers = styled.div`
  display: flex;
  height: 95vh;
  align-items: flex-end;
`;

export const SearchButton = styled.span`
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  color: rgba(0, 0, 0, 0.85);
  display: inline-flex;
  justify-content: center;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 1.5vh;

  &:hover,
  &:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.65);
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;
